// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: { apiKey: "AIzaSyDKOwdqrhkZfeHLNXGAcZg1_MgsImPp0tk",
              authDomain: "mywebsite-3c38c.firebaseapp.com",
              projectId: "mywebsite-3c38c",
              storageBucket: "mywebsite-3c38c.appspot.com",
              messagingSenderId: "720722532532",
              appId: "1:720722532532:web:7eca015b73c4a62b865fe1",
              measurementId: "G-JXNTMLL85F"
          }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
