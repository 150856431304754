<div class="container">

    <div class="section-title">
      <h2>Personal Projects</h2>
    </div>
    <script src="https://code.iconify.design/1/1.0.7/iconify.min.js"></script>
    <span class="iconify" data-icon="bx:bx-bus" data-inline="false"></span>
    <span class="iconify" data-icon="bx:bxs-bus-school" data-inline="false"></span>

      <div class="row">
        <div class="col-sm">
          <h5 class="portTitle">Development of CAD/CAM system for an ultrasonic impact grinding machine (2015)</h5>
          <span class="description">Using Visual Basic, OpenSCAD, OpenTK/OpenGL to build a 3D CAD/CAM</span> 
        </div>
        <div class="col-sm">
          <h5 class="portTitle">Dublin bikes occupancy web application (2017)</h5>
          <span class="description">Construction of a responsive website using Pythons FLASK framework</span>
        </div>
        <div class="col-sm">
          <h5 class="portTitle">Supervised machine learning to improve Dublin Bus (2017)</h5>
          <span class="description">Using Scikit-learn, pandas and numpy to perform supervised machine learning
            techniques to improve Dublin bus journey time predictions.</span>
        </div>
      </div>
      <hr>

      <div class="row">
        <div class="col-sm">
          <h5 class="portTitle">Android augmented reality app (2018)</h5>
          <span class="description">FireBase and GeoFire for GPS user activity</span>
        </div>
        <div class="col-sm">
          <h5 class="portTitle">SecurityCamera – Face Recognition streaming video (2019)</h5>
          <span class="description">OpenCV (Used to detect face and body within an image)</span>
        </div>
        <div class="col-sm">
          <h5 class="portTitle">CRM(Customer relationship management) Platform (2020)</h5>
          <span class="description">FireBase(Login), Python(Django) backend, Angular front end, MySQL Database.</span>
        </div>
      </div>

</div>



