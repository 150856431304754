<div class="container">

    <div class="section-title">
      <h2>Contact</h2>
    </div>

    <div class="row">

      <div class="col-lg-5 d-flex align-items-stretch">
        <div class="info">
          <div class="address">
            <i class="bi bi-geo-alt"></i>
            <h4>Location:</h4>
            <p>25 Cabot Square, Canary Wharf, London.</p>
          </div>

          <div class="email">
            <i class="bi bi-envelope"></i>
            <h4>Email:</h4>
            <p>removed for now - Too much spam :)</p>
          </div>

          <div class="phone">
            <i class="bi bi-phone"></i>
            <h4>Call:</h4>
            <p>removed for now - Too much spam :)</p>
          </div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4966.857069243403!2d-0.02548612264683116!3d51.505353596623436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602ba7a12992f%3A0x4d821857a5e4a41!2sCanary%20Wharf%2C%20London!5e0!3m2!1sen!2suk!4v1626639094959!5m2!1sen!2suk" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
        </div>

      </div>

      <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
        <div class="php-email-form">
          <div class="row">
            <div class="col-md-6">
              <label for="name">Your Name</label>
              <input [(ngModel)]="name" type="text" name="name" class="form-control" id="name" required>
            </div>
            <div class="col-md-6">
              <label for="name" >Your Email</label>
              <input type="email" [(ngModel)]="email"  class="form-control" name="email" id="email" required>
            </div>
          </div>
          <div>
            <label for="name">Subject</label>
            <input type="text" [(ngModel)]="subject"  class="form-control" name="subject" id="subject" required>
          </div>
          <div>
            <label for="name">Message</label>
            <textarea class="form-control" [(ngModel)]="message"  name="message" rows="10" required></textarea>
          </div>
          <div class="text-center"><button class="btn btn-info" (click)="buttonClicked()">Send Message</button></div>
        </div>
      </div>

    </div>

  </div>