<div class="container">

    <div class="section-title">
      <h2>Resume</h2>
    </div>

    <div class="row">
      <div class="col-lg-6" data-aos-delay="100">
        <h3 class="resume-title">Summary</h3>
        <div class="resume-item">
            <h4>Joe Kelly</h4>
            <p>Full stack software developer living in London. I have 3 years experience with software development
               and 1 year working in middle office for a hedge fund. I currently work on the Morgan Stanleys team that is 
              responsible for OTC Payments.</p>
        </div>
        
        <h3 class="resume-title">Professional Experience</h3>
        <div class="resume-item">
          <h4>Software Developer</h4>
          <h5>2018 - Present</h5>

          <p><em>Morgan Stanley, London, UK.</em></p>
          <ul>
            <li>PB Matrix integration - Development of a new Settlements Platform for external clients (Java, Angular, Python) 2021 - present</li>
            <li>Magnet team - Development of Equity Swaps Renovated Payment Engine (Java, Sybase, MQ) 2018 - 2020</li>
            <li>Production support - Point of contact when issues arise in the system.</li>
            <li>Agile Development - Liaising with product managers throughout the development life cycle.</li>
          </ul>
        </div>
        <div class="resume-item">
          <h4>Operational Control Analyst</h4>
          <h5>2015 - 2016</h5>
          <p><em>Global Asset Management, Dublin, Ireland.</em></p>
          <ul>
            <li>Oversight & control of the outsourced administration and custodian services through daily &
                periodic control checks and qualitative reviews – including the NAVs produced by State Street.</li>
            <li>Supervise the reconciliation of positions and values between GAM and State Street records,
                ensuring that any discrepancies are followed through to resolution.</li>
            <li>Validate pricing methodologies and ensure appropriate price agreements are maintained in line
                with market standard with the use of Bloomberg terminals and Reuters.</li>
            <li>Work on projects across the various different business functions – including fund
                launches/closures, new investments and VBA software projects.</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6">
        <h3 class="resume-title">Education</h3>
        <div class="resume-item">
          <h4>Master of Computer Science - Level 9</h4>
          <h5>2016 - 2018</h5>
          <p><em>University College Dublin</em></p>
          <p>Thesis: Using supervised machine learning techniques to improve predictions of Dublin Bus</p>
        </div>
        <div class="resume-item">
          <h4>BSc. Engineering with Management level 8</h4>
          <h5>2011 - 2015</h5>
          <p><em>Trinity College Dublin</em></p>
          <p>Awards: Crossland award – Graduating top of steam</p>
          <p>Thesis: Development of CAD/CAM system for an ultrasonic impact grinding machine</p>
        </div>
        <div class="resume-item">
            <h4>Prof. Level 8 Cert. in Operational Risk Management in Investment Fund Services</h4>
            <h5>2016</h5>
            <p><em>University College Dublin</em></p>
            <p>Risk Management Framework, Culture and Best Practices.</p>
        </div>
      <h3 class="resume-title">Other Jobs</h3>
        <div class="resume-item">
            <h4>Camp Foley Minnesota</h4>
            <h5>2015</h5>
            <p><em>Camp Counselor</em></p>
        </div>
        
        <div class="resume-item">
          <h4>Supersavers Opticians</h4>
          <h5>2011 - 2013</h5>
          <p><em>Lab Technician</em></p>
        </div>
        <div class="resume-item">
          <h4>Bombay Pantry</h4>
          <h5>2011 - 2014</h5>
          <p><em>Delivery Driver</em></p>
        </div>
    </div>

  </div>