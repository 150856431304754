<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Joe Kelly</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Typed text (animated typing effect)-->
<script type="text/javascript" src="https://unpkg.com/typed.js@~2.0.11"></script>

<!-- ScrollMonitor (manages events for elements scrolling in and out of view) -->
<script type="text/javascript" src="https://unpkg.com/scrollmonitor@~1.2.4"></script>

  <!-- Favicons -->
  <link href="../assets/img/favicon.png" rel="icon">
  <link href="../assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>


  <!-- ======= Header ======= -->
  <header id="header">
    <div class="d-flex flex-column">

      <div class="profile">
        <img src="../assets/img/profile-bugsy.png" class="img-fluid rounded-circle">
        <h1 class="text-light"><a href="index.html">Joe Kelly</a></h1>
        <div class="social-links mt-3 text-center">
          <a href="https://www.linkedin.com/in/joe-kelly-software-developer/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          <a href="https://github.com/kellyj40" class="linkedin"><i class="bx bxl-github"></i></a>
        </div>
      </div>

      <nav class="nav-menu navbar">
        <ul>
          <li><a href="#home" [ngClass]="{'active': currentlySelected === 'homeid' }" class="nav-link scrollto" (click)="changeActiveTab('homeid')"><i class="bx bx-home"></i> <span>Home</span></a></li>
          <li><a href="#about" [ngClass]="{'active': currentlySelected === 'aboutid' }" class="nav-link scrollto" (click)="changeActiveTab('aboutid')"><i class="bx bx-user"></i> <span>About</span></a></li>
          <li><a href="#skills" [ngClass]="{'active': currentlySelected === 'skillsid' }" class="nav-link scrollto" (click)="changeActiveTab('skillsid')"><i class="bx bx-user"></i> <span>Skills</span></a></li>
          <li><a href="#resume" [ngClass]="{'active': currentlySelected === 'resumeid' }" class="nav-link scrollto" (click)="changeActiveTab('resumeid')"><i class="bx bx-file-blank"></i> <span>Resume</span></a></li>
          <li><a href="#portfolio" [ngClass]="{'active': currentlySelected === 'portfolioid' }" class="nav-link scrollto" (click)="changeActiveTab('portfolioid')"><i class="bx bx-book-content"></i> <span>Portfolio</span></a></li>
          <li><a href="#contact" [ngClass]="{'active': currentlySelected === 'contactid' }" class="nav-link scrollto" (click)="changeActiveTab('contactid')"><i class="bx bx-envelope"></i> <span>Contact</span></a></li>
        </ul>
      </nav><!-- .nav-menu -->
    </div>
  </header><!-- End Header -->

  <!-- ======= home Section ======= -->
  <section id="home" class="d-flex flex-column justify-content-center align-items-center">
    <div class="home-container">
      <div class="typewriter">
        <h1>Fullstack developer</h1>
      </div>
    </div>
  </section><!-- End home -->

  

  <main id="main">
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <app-about></app-about>
    </section><!-- End About Section -->

    <!-- ======= Resume Section ======= -->
    <section id="skills" class="skills section-bg">
      <app-skills></app-skills>
    </section><!-- End Resume Section -->

    <!-- ======= Resume Section ======= -->
    <section id="resume" class="resume">
      <app-resume></app-resume>
    </section><!-- End Resume Section -->

    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio section-bg">
      <app-portfolio></app-portfolio>
    </section><!-- End Portfolio Section -->


    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <app-contact></app-contact>
    </section><!-- End Contact Section -->

  </main><!-- End #main -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>



</body>

</html>