import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class FirebaseServiceService {

  constructor(private firestore: AngularFirestore) { }


  createMessage(data){

    return new Promise<any>((resolve, reject) =>{
      this.firestore
          .collection("messages")
          .add(data)
          .then(res => {}, err => reject(err));
    });


  }
}
