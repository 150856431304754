
<div class="container">

        <div class="row skills-content">
            <div class="section-title">
                <h2>Skills</h2>
            </div>

            <div class="col-lg-6 aos-init aos-animate">
        
              <div class="progress">
                <span class="skill">Java <i class="val">100%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
                </div>
              </div>
        
              <div class="progress">
                <span class="skill">Python <i class="val">90%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%;"></div>
                </div>
              </div>
        
              <div class="progress">
                <span class="skill">Angular/Typescript <i class="val">75%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%;"></div>
                </div>
              </div>
            </div>
        
            <div class="col-lg-6 aos-init aos-animate">
        
              <div class="progress">
                <span class="skill">Sybase <i class="val">80%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%;"></div>
                </div>
              </div>
        
              <div class="progress">
                <span class="skill">Linux <i class="val">90%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%;"></div>
                </div>
              </div>
        
              <div class="progress">
                <span class="skill"> HTML/CSS <i class="val">55%</i></span>
                <div class="progress-bar-wrap">
                  <div class="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100" style="width: 55%;"></div>
                </div>
              </div>
        
            </div>
    </div>
</div>