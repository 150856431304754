import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  type_text = "";
  full_text = ['a', 'full', 'stack', 'developer']
  title = 'angular-amplify-app';
  currentlySelected = "homeid";

  changeActiveTab(idToActive){
    console.log(idToActive);
    this.currentlySelected = idToActive;
  }


}



