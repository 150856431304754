<div class="container">

    <div class="section-title">
      <h2>About</h2>
      <h3>Fullstack Developer</h3>
    </div>
        <div class="row">
          <div class="col-lg-6">
            <p>
              An experienced full stack developer working in the financial industry.
              I am an award winning graduate from Trinity College Dublin with a MSc from the University College
              Dublin in Computer Science. 
              I have experience in the finance industry, both as a middle office operations
              analyst and a software developer. I have a keen interest in the European and US equity markets, and an aptitude for, solving logical and
              mathematical problems through computer programming.
              I am also an avid traveler having visited over 30+ countries. 
            </p>
          </div>
          <div class="col-lg-6">
            <ul>
              <li><i class="bi bi-chevron-right"></i> <strong>Age:</strong> <span> 28</span></li>
              <li><i class="bi bi-chevron-right"></i> <strong>Degree:</strong> <span> Engineering</span></li>
              <li><i class="bi bi-chevron-right"></i> <strong>Masters:</strong> <span> Computer Science</span></li>
              <li><i class="bi bi-chevron-right"></i> <strong>City:</strong> <span> London/Dublin</span></li>
              <li><i class="bi bi-chevron-right"></i> <strong>Languages:</strong> <span> English, Irish</span></li>
              <li><i class="bi bi-chevron-right"></i> <strong>Nationality:</strong> <span> Irish/European</span></li>
              <li><i class="bi bi-chevron-right"></i> <strong>Hobbies:</strong> <span> Travelling, Football, Hikes, Squash</span></li>
            </ul>
          </div>
        </div>

  </div>