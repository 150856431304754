import { Component, OnInit } from '@angular/core';
import { FirebaseServiceService } from './firebase-service.service';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {formatDate} from '@angular/common';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  name: string;
  email: string;
  subject: string;
  message: string;
  


  constructor(private firbaseService:FirebaseServiceService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  buttonClicked() {

      var message = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
        postDate: formatDate(new Date(), 'yyyy/MM/dd', 'en')
      };

      this.firbaseService.createMessage(message);
      this.toastr.success("I will be in contact ASAP.. :)", "Message Sent");


  }
}
